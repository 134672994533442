import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import router from '@/router'
import { notification } from 'ant-design-vue'

const ls = new SecureLS({ isCompression: false })

export const authStore = defineStore('auth', () => {
  const loading = ref(false)
  const token = ref(ls.get('token') || null)
  const user = ref(ls.get('user') || null)
  const loggedIn = computed(() => !!token.value)
  const isInternal = computed(() => {
    if (user.value && user.value.roleNames) {
      return user.value.roleNames.includes('employee')
    }

    return false
  })

  function login (username:string, password:string) {
    return new Promise<void>((resolve, reject) => {
      axios.post(process.env.VUE_APP_API_URL + '/login', {
        username: username,
        password: password
      }).then((res) => {
        // Set authenticated user.
        user.value = res.data.user
        ls.set('user', user.value)

        // Set token.
        token.value = 'Bearer ' + res.data.access_token
        ls.set('token', token.value)

        // Set bearer token for axios calls.
        axios.defaults.headers.common.Authorization = token.value

        axios.get(process.env.VUE_APP_API_URL + '/users/constructionSiteApp/settings').then((r) => {
          if (r.data.length) {
            r.data.forEach((setting:{ id:number, setting_name:string, setting_value:never }) => {
              new SecureLS({ isCompression: false }).set(setting.setting_name, setting.setting_value)
            })
          }
          resolve()
        }).catch((e) => {
          reject(e)
        })
      }).catch((e) => {
        reject(e)
      })
    })
  }

  function getDetailsForO365User (bearerToken:string) : Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // Set token.
      token.value = 'Bearer ' + bearerToken
      ls.set('token', token.value)

      axios.defaults.headers.common.Authorization = token.value
      axios.get(process.env.VUE_APP_API_URL + '/users/o365').then((r) => {
        // Set authenticated user.
        user.value = r.data.user
        ls.set('user', user.value)

        axios.get(process.env.VUE_APP_API_URL + '/users/constructionSiteApp/settings').then((r) => {
          if (r.data.length) {
            r.data.forEach((setting:{ id:number, setting_name:string, setting_value:never }) => {
              new SecureLS({ isCompression: false }).set(setting.setting_name, setting.setting_value)
            })
          }
          resolve()
        }).catch((e) => {
          reject(e)
        })
      }).catch((e) => {
        // Clear token and localstorage.
        token.value = null
        ls.clear()

        // Remove bearer token for axios calls.
        delete axios.defaults.headers.common.Authorization
        reject(e)
      })
    })
  }

  function logout (forcedLogout = false) {
    axios.post(process.env.VUE_APP_API_URL + '/logout')

    // Clear token and localstorage.
    token.value = null
    ls.clear()

    // Remove bearer token for axios calls.
    delete axios.defaults.headers.common.Authorization

    if (forcedLogout) {
      router.push('/').then(() => {
        user.value = null
        notification.info({
          message: 'Sessie verlopen!',
          description: 'Uw sessie is verlopen en u bent automatisch uitgelogd. Om verder te gaan kunt u opnieuw inloggen.',
          duration: 5
        })
      })
    } else {
      router.push('/').then(() => {
        user.value = null
        notification.success({
          message: 'Succesvol uitgelogd.',
          description: 'Uw bent succesvol uitgelogd. U kunt dit scherm nu sluiten.',
          duration: 5
        })
      })
    }
  }

  function forgotPassword (email:string) : void {
    loading.value = true
    axios.post(process.env.VUE_APP_API_URL + '/password/reset', { app: 'construction-site-portal', email: email }).then(() => {
      notification.success({
        message: 'Wachtwoord herstel aangevraagd.',
        description: 'Er is een e-mail met verdere instructies verzonden naar het door u opgegeven e-mailadres.',
        duration: 5
      })
      router.push({ name: 'Login' })
    }).catch(() => {
      notification.error({
        message: 'Wachtwoord herstellen mislukt!',
        description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
        duration: 0
      })
    }).finally(() => {
      loading.value = false
    })
  }

  function resetPassword (data:{ email:string|null, activationKey:string|null, password:string|null, passwordConfirmation:string|null }) : void {
    loading.value = true
    axios.post(process.env.VUE_APP_API_URL + '/password/activate', data).then((r) => {
      if (r.status === 200 && r.data !== 'Account already activated!') {
        notification.success({
          message: 'Account geactiveerd.',
          description: 'Uw account is succesvol geactiveerd. U kunt nu inloggen met het door u gekozen wachtwoord.',
          duration: 5
        })
        router.push({ name: 'Login' })
      }
    }).catch((e) => {
      if (e.response.data === 'Already activated.') {
        notification.warning({
          message: 'Account activeren mislukt!',
          description: 'De door u gebruikte persoonlijke activatiecode is al eens gebruikt. Vraag een nieuwe persoonlijke activatiecode aan.',
          duration: 0
        })
        router.push({ name: 'ForgotPassword' })
      } else {
        notification.error({
          message: 'Account activeren mislukt!',
          description: 'Er is helaas iets mis gegaan. Probeer het later nog een keer.',
          duration: 0
        })
      }
    }).finally(() => {
      loading.value = false
    })
  }

  return { loading, user, loggedIn, isInternal, login, getDetailsForO365User, logout, forgotPassword, resetPassword }
})
